<template>
<!-- 注册 -->
  <div class="Register">
      <div class="register-inner">
          <div class="reg-main">
              <div class="reg-tit">
              <h1>欢迎注册</h1>
                  <!-- <h2>多种API供您选择</h2> -->
              </div>
              <div class="reg-form">
                  <form id="registerFrom" name="registerFrom">
                    <div class="reg-row">
                        <el-row>
                            <el-col :span="4">
                                <label for="">
                                    用户名 <span>*</span>
                                </label>
                            </el-col>
                            <el-col :span="20">
                                <el-input placeholder="请输入用户名" id="username" name="username" v-model.trim="username" clearable></el-input>
                                <p class="error" v-show="usernameShow">
                                    <span class="tip"></span>
                                    <span class="info">请输入用户名</span>
                                </p>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="reg-row">
                        <el-row>
                            <el-col :span="4">
                                <label for="">
                                    密码 <span>*</span>
                                </label>
                            </el-col>
                            <el-col :span="20">
                                 <el-input placeholder="请输入密码*" id="password" name="password" v-model="password" clearable show-password></el-input>
                                <p class="error" v-show="passwordShow">
                                    <span class="tip"></span>
                                    <span class="info">请输入密码</span>
                                </p>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="reg-row">
                        <el-row>
                            <el-col :span="4">
                                <label for="">
                                    确认密码 <span>*</span>
                                </label>
                            </el-col>
                            <el-col :span="20">
                                <el-input placeholder="请再次输入密码*" id="passwordAgain" name="passwordAgain" v-model="passwordAgain" clearable show-password></el-input>
                                <p class="error" v-show="passwordAgainErr">
                                    <span class="tip"></span>
                                    <span class="info">两次输入密码不一致!</span>
                                </p>
                                <p class="error" v-show="passwordAgainShow">
                                    <span class="tip"></span>
                                    <span class="info">请再次输入密码</span>
                                </p>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="reg-row">
                        <el-row>
                            <el-col :span="4">
                                <label for="">
                                    公司名称 <span>*</span>
                                </label>
                            </el-col>
                            <el-col :span="20">
                                <el-input placeholder="请输入公司名称" id="company_name" name="company_name" v-model.trim="company_name" clearable></el-input>
                                <p class="error" v-show="companyNameShow">
                                    <span class="tip"></span>
                                    <span class="info">请输入公司名称</span>
                                </p>
                            </el-col>
                        </el-row>
                    </div> 
                    <div class="reg-row">
                        <el-row>
                            <el-col :span="4">
                                <label for="">
                                    邮箱 <span>*</span>
                                </label>
                            </el-col>
                            <el-col :span="20">
                                <el-input placeholder="请输入邮箱" id="email" name="email" v-model.trim="email" clearable @input="checkemail(email)"></el-input>
                                <p class="error" v-show="emailShow">
                                    <span class="tip"></span>
                                    <span class="info">请输入正确的邮箱</span>
                                </p>
                            </el-col>
                        </el-row>
                    </div> 
                    <div class="reg-row">
                         <el-row>
                            <el-col :span="4">
                                <label for="">
                                    手机号 <span>*</span>
                                </label>
                            </el-col>
                            <el-col :span="20">
                                <el-input placeholder="请输入手机号" name="phone" id="phone" v-model.trim="phone" clearable @input="checkPhone(phone)"></el-input>
                                <p class="error" v-show="phoneShow">
                                    <span class="tip"></span>
                                    <span class="info">请输入正确的手机号</span>
                                </p>
                            </el-col>
                        </el-row>
                    </div>
                    <el-divider>以下选填</el-divider>
                    <div class="reg-row row-file">
                         <el-row>
                            <el-col :span="4">
                                <label for="">
                                    营业执照
                                </label>
                            </el-col>
                            <el-col :span="20">
                                <input type="text" id="file1" readonly="readonly" placeholder="请上传营业执照" :value = license @change="filesControl" >
                                <a href="javascript:void(0)" class="btn-a">浏览</a>
                                <input type="file" name="license" id="license" @change="fileUpload">
                            </el-col>
                        </el-row>
                    </div>
                    <div class="reg-row row-file">
                        <el-row>
                            <el-col :span="4">
                                <label for="">
                                    头像
                                </label>
                            </el-col>
                            <el-col :span="20">
                                <input type="text" id="file" readonly="readonly" placeholder="请上传头像" :value = icon @change="iconControl">
                                <a href="javascript:void(0)" class="btn-a">浏览</a>
                                <input type="file" name="icon" id="icon" @change="iconUpload">
                            </el-col>
                        </el-row>
                    </div>
                    <div class="reg-row">
                        <el-row>
                            <el-col :span="4">
                                <label for="">
                                    公司联系人
                                </label>
                            </el-col>
                            <el-col :span="20">
                                <el-input placeholder="请输入公司联系人" id="contacts" name="contacts" v-model="contacts" clearable></el-input>
                            </el-col>
                        </el-row>
                    </div>
                    
                    <!-- <div class="reg-code">
                        <el-input class="reg-input-short" v-model="code" placeholder="手机短信验证码" clearable></el-input>
                        <button class="reg-smsbtn">获取验证码</button>
                    </div> -->
                    <div class="reg-row">
                        <button  type="button" class="reg-btn" @click="registerNow()">立即注册</button>
                    </div>   
                  </form>
              </div>
          </div>
      </div>
  </div>
</template>

<script>

    import Register from './Register.js';
    export default Register;
</script>

<style scoped>
    @import './Register.css';
</style>