import axios from 'axios'
import Register from './Register.vue'
export default {
    components: {
        Register
    },
    data() {
        return {
            username:'',//test
            password:'',//123456
            passwordAgain:'',//123456
            company_name:'',//testg公司
            email:'',//123456@16.com
            phone:'',//13541244122
            license:'',
            icon:'',
            contacts:'',//111
            usernameShow:false,
            passwordShow:false,
            passwordErr:false,
            passwordAgainErr:false,
            passwordAgainShow:false,
            companyNameShow:false,
            emailShow:false,
            phoneShow:false,

        }
    },
    methods:{
        // 邮箱验证
        checkemail(val){
            // var re = /^[a-zA-Z0-9_-_.]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
            var re = /^[A-Za-z0-9_\-.\u4e00-\u9fa5]+@[A-Za-z0-9_\-.]+(\.[A-Za-z]{2,8})+$/;
            var objExp = new RegExp(re)
            if(objExp.test(val) == true){
                this.emailShow = false
            }else{
                this.emailShow = true
            }
        },
        // 验证手机号
        checkPhone(val) {
            var myreg = /(^1|^84)(\d{10}|\d{9})$/;
            if(!myreg.test(val)) {
                this.phoneShow = true;
            } else {
                this.phoneShow = false;
            }
        },
        // 文件上传
        fileUpload(){
            var license = document.getElementById("license")
            var arrs = license.value.split("\\");
            this.license = arrs[arrs.length-1] 
            this.filesControl()
        },
        // 文件格式大小限制
        filesControl(){
            var license = document.getElementById("license")
            var isIE = /msie/i.test(navigator.userAgent) && !window.opera;   
            var fileSize = 0;
            var fileTypes = [".jpg",'.gif','.bmp','.png','.tif','.pdf']
            var filepath = license.value
            var filemaxsize = 1024*10  // 10M
            if(filepath){
                var isnext = false
                var fileend = filepath.substring(filepath.indexOf("."))
                if(fileTypes && fileTypes.length>0){
                    for(var i=0; i<fileTypes.length; i++){
                        if(fileTypes[i]==fileend){
                            isnext = true;
                            break;
                        }
                    }
                }
                if(!isnext){
                    this.$message({
                        showClose: true,
                        message: '不接受此文件类型',
                        type: 'warning',
                        center: true
                    });
                    this.license = '';
                    license.value = ''  
                    return false
                }
            }else{
                return false;
            }
            if(isIE && !license.files){
                var filePath = license.value;
                // eslint-disable-next-line no-undef
                var fileSystem = new ActiveXObject("Scripting.FileSystemObject"); 
                if(!fileSystem.FileExists(filePath)){    
                    this.$message({
                        showClose: true,
                        message: '附件不存在，请重新上传！',
                        type: 'warning',
                        center: true
                    });
                    return false;   
                }
                var file = fileSystem.GetFile (filePath);   
                fileSize = file.Size;   
            }else{
                fileSize = license.files[0].size;  
            }
            var size = fileSize / 1024;   
            if(size>filemaxsize){      
                this.$message({
                    showClose: true,
                    message:"附件大小不能大于"+filemaxsize/1024+"M！",
                    type: 'warning',
                    center: true
                });
                this.license =""; 
                console.log(license.value);
                license.value = ''  
                return false;   
            }  
            if(size<=0){    
                this.$message({
                    showClose: true,
                    message:'附件大小不能为0M！',
                    type: 'warning',
                    center: true
                });
                this.license ="";   
                license.value = ''  
                return false;   
            } 
        },
        // 头像上传
        iconUpload(){
            var icon = document.getElementById("icon")
            var arrs = icon.value.split("\\");
            this.icon = arrs[arrs.length-1]  
            this.iconControl()
        },
        // 头像格式大小限制
        iconControl(){
            var icon = document.getElementById("icon")
            var isIE = /msie/i.test(navigator.userAgent) && !window.opera;   
            var fileSize = 0;
            var fileTypes = [".jpg",'.png','.jpeg']
            var filepath = icon.value
            var filemaxsize = 1024  // 1M
            if(filepath){
                var isnext = false
                var fileend = filepath.substring(filepath.indexOf("."))
                if(fileTypes && fileTypes.length>0){
                    for(var i=0; i<fileTypes.length; i++){
                        if(fileTypes[i]==fileend){
                            isnext = true;
                            break;
                        }
                    }
                }
                if(!isnext){
                    this.$message({
                        showClose: true,
                        message: '不接受此文件类型',
                        type: 'warning',
                        center: true
                    });
                    this.icon = '';
                    icon.value = ''
                    return false
                }
            }else{
                return false;
            }
            if(isIE && !icon.files){
                var filePath = icon.value;
                // eslint-disable-next-line no-undef
                var fileSystem = new ActiveXObject("Scripting.FileSystemObject"); 
                if(!fileSystem.FileExists(filePath)){    
                    this.$message({
                        showClose: true,
                        message: '附件不存在，请重新上传！',
                        type: 'warning',
                        center: true
                    });
                    return false;   
                }
                var file = fileSystem.GetFile (filePath);   
                fileSize = file.Size;   
            }else{
                fileSize = icon.files[0].size;  
            }
            var size = fileSize / 1024;   
            if(size>filemaxsize){      
                this.$message({
                    showClose: true,
                    message:"附件大小不能大于"+filemaxsize/1024+"M！",
                    type: 'warning',
                    center: true
                });
                this.icon = "";   
                icon.value = ''
                return false;   
            }  
            if(size<=0){    
                this.$message({
                    showClose: true,
                    message:'附件大小不能为0M！',
                    type: 'warning',
                    center: true
                });
                this.icon ="";  
                icon.value = '' 
                return false;   
            } 
        },

        // 注册
        registerNow(){
            if(this.username === ''){
                this.usernameShow = true
            }else if(this.password === ''){
                this.usernameShow = false
                this.passwordShow = true
            }else if(this.passwordAgain === ''){
                this.passwordShow = false
                this.passwordAgainShow = true
            }else if(this.password != this.passwordAgain){
                this.passwordAgainShow = false
                this.passwordAgainErr = true
            }else if(this.company_name === ''){
                this.passwordAgainErr = false
                this.companyNameShow = true
            }else if(this.email === ''){
                this.companyNameShow = false
                this.emailShow = true
            }else if(this.phone === ''){
                this.emailShow = false
                this.phoneShow = true
            }else {
                this.phoneShow = false
                var registerFrom = document.getElementById('registerFrom')
                var fd = new FormData(registerFrom)
                axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
                axios.post(this.GLOBAL.BASE_URL1 + '/user/register',fd )
                .then(res=>{
                    if(res.data.status_code === 0){
                        this.$message({
                            type: 'success',
                            message: '注册成功',
                            showClose: true,
                            center: true
                        });
                        this.username = ''
                        this.password = ''
                        this.passwordAgain = ''
                        this.company_name = ''
                        this.email = ''
                        this.phone = ''
                        this.license = ''
                        this.icon = ''
                        this.contacts = ''
                    }else {
                        this.$message({
                            type: 'error',
                            message: res.data.message,
                            showClose: true,
                            center: true
                        });
                    }
                })
                .catch(error=>{
                    console.log(error);
                })
                
            }
           
        }
    }
}